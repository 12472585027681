.options-button {

}

.options {

}

.sizeinput {
    width: 20px;
}
