.stats-panel {
    /*width: 18%;*/
    border: black 1px solid;
    margin: 10px;
    /*padding: 10px;*/
    /*grid-template-rows: 20px*/
}

.stats-panel--interior {
    display: grid;
    grid-template-columns: 1fr .5fr;
}

.stat-name, .stat-value {
    margin: 5px;
    margin-left: 10px;
}

.stat-name {
    font-style: italic;
    text-align: left;
}

.stat-value {
    text-align: left;

}