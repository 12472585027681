
.play-board {
    --board-height: 7;
    --board-width: 7;

    --side-color: black;
    --top-bottom-color: black;

    --scale: 1.0;
    --default-square-size: 50px;
    --default-hint-height: 70px;
    --square-size: calc(var(--default-square-size) * var(--scale));
    --hint-height: calc(var(--default-hint-height) * var(--scale));
    --border-width: 10px;
    --gap: calc(4px * var(--scale));
    --gap-width: calc((var(--board-width)) * var(--gap));
    --square-width: calc((var(--board-width)) * var(--square-size));

    width: calc(var(--hint-height) + var(--gap-width) + var(--square-width));
    border-top: var(--border-width) solid var(--top-bottom-color);
    border-bottom: var(--border-width) solid var(--top-bottom-color);
    border-left: var(--border-width) solid var(--side-color);
    border-right: var(--border-width) solid var(--side-color);

    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.play-board--completed {
    --side-color: green;
    --top-bottom-color: green;
    /*border-width: 10px;*/
    /*background-color: green;*/
}

.play-board--incomplete {

}

.play-board__grid {
    display: grid;
    gap: var(--gap);
    grid-template-columns: var(--hint-height) repeat(var(--board-width), var(--square-size));
    grid-template-rows: var(--hint-height) repeat(var(--board-height), var(--square-size));
}

.play-board--topcorner {

}

.play-board__count {
    background-color: rgba(165, 42, 42, 0.32);
    color: black;
    font-size: x-large;
    font-weight: bold;
}

.play-board__count--row {

    /*margin-right: var(--gap);*/
    /*position: relative;*/
    /*right: var(--gap);*/
    /*top: var(--gap);*/
}

.play-board__count--col {
    /*position: relative;*/
    /*bottom: -20px;*/

}

/*.play-board__count__text {*/
/*    !*text-align: center;*!*/
/*    !*vertical-align: center;*!*/
/*    position: relative;*/
/*}*/
.play-board--colorful-counters .play-board__count--undersatisfied {
    color: black;
}

.play-board--colorful-counters .play-board__count--floor-saturated {
    color: #ffff71;
}

.play-board--colorful-counters .play-board__count--satisfied {
    color: green;
}

.play-board--colorful-counters .play-board__count--oversatisfied {
    color: red;
}



.block-square {
    width: var(--square-size);
    height: var(--square-size);
}


.block-square--user-floor {
    /*background-color: darkgray;*/
    /*background-image: url("../assets/cross-svgrepo-com.svg");*/
    background-image: url("../assets/largeorangediamond-svgrepo-com.svg");
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: whitesmoke;
}

.block-square--user-wall {
    background-color: saddlebrown;
    background-image: url("../assets/brick-wall-wall-svgrepo-com.svg");
}

/* To darken the block where we've put too many in its row or column. */
.block-square--user-wall.block-square--overflow {
    background-blend-mode: color;

}

.block-square--user-untouched {
    background-color: whitesmoke;
}

.block-square--immutable {
    background-color: white;
}

.block-square--deadend {
    /*background-color: gold;*/
    background-size: contain;
    background-position: center;
}

.block-square--treasure {
    /*background-color: gold;*/
    background-image: url("../assets/treasure-chest-svgrepo-com.svg");
    background-size: contain;
    background-position: center;
}
